import React, { useState, useEffect } from "react";
import {
	BalanceBarContainer,
	IconContainer,
	WalletButtonContainer,
	NavItem,
	NavText,
	BalanceAmount,
	Icon,
	WalletButton,
} from "./BalanceBar.styles";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import GameTypes from "../../constants/GameTypes";
import SearchIcon from "../../assets/BalanceBar/search.svg";
import SearchIconActive from "../../assets/BalanceBar/searchActive.svg";
import TurIcon from "../../assets/BalanceBar/tur.svg";
import TurIconActive from "../../assets/BalanceBar/turActive.svg";
import WalletIcon from "../../assets/BalanceBar/wallet.svg";
import LikeIcon from "../../assets/like.svg";
import LikeIconActive from "../../assets/likeActive.svg";
import ProfileIcon from "../../assets/BalanceBar/profile.svg";
import ProfileIconActive from "../../assets/BalanceBar/profileActive.svg";
import { useWalletInfo } from "../../contexts/WalletInfoContext";
import { useTranslation } from "../../contexts/TranslationContext";

const BalanceBar = ({ openSearch, openWallet }) => {
	const { balance } = useWalletInfo();
	const [activeTab, setActiveTab] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();

	useEffect(() => {
		const pathname = location.pathname;

		// Определяем активную вкладку
		if (pathname === `/games/${GameTypes.HISTORY}`) {
			setActiveTab("history");
		} else if (pathname === `/games/${GameTypes.FAVOURITE}`) {
			setActiveTab("favourites");
		} else if (pathname === "/tournaments") {
			setActiveTab("tournaments");
		} else {
			setActiveTab("");
		}
	}, [location, searchParams]);

	const handleHistoryNavigation = () => {
		navigate(`/games/${GameTypes.HISTORY}`);
	};

	const handleFavouritesNavigation = () => {
		navigate(`/games/${GameTypes.FAVOURITE}`);
	};

	const handleTournamentsNavigation = () => {
		navigate("/tournaments");
	};

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};

	return (
		<>
			<BalanceBarContainer>
				<IconContainer>
					<NavItem onClick={() => openSearch()}>
						<Icon
							src={activeTab === "search" ? SearchIconActive : SearchIcon}
							alt={t("search")}
						/>
						<NavText isActive={activeTab === "search"}>{t("search")}</NavText>
					</NavItem>
					<NavItem
						onClick={() => {
							handleTournamentsNavigation();
							handleTabClick("tournaments");
						}}
					>
						<Icon
							src={activeTab === "tournaments" ? TurIconActive : TurIcon}
							alt={t("tournaments")}
						/>
						<NavText isActive={activeTab === "tournaments"}>{t("tournaments")}</NavText>
					</NavItem>
				</IconContainer>

				<WalletButtonContainer>
					<WalletButton onClick={() => openWallet()}>
						<Icon
							src={WalletIcon}
							alt="Wallet"
						/>
					</WalletButton>
					<BalanceAmount>{balance !== null ? balance + "$" : t("loading")}</BalanceAmount>
				</WalletButtonContainer>

				<IconContainer>
					<NavItem
						onClick={() => {
							handleFavouritesNavigation();
							handleTabClick("favourites");
						}}
					>
						<Icon
							src={activeTab === "favourites" ? LikeIconActive : LikeIcon}
							alt={t("favourites")}
						/>
						<NavText isActive={activeTab === "favourites"}>{t("favourites")}</NavText>
					</NavItem>
					<NavItem
						onClick={() => {
							handleHistoryNavigation();
							handleTabClick("history");
						}}
					>
						<Icon
							src={activeTab === "history" ? ProfileIconActive : ProfileIcon}
							alt={t("profile")}
						/>
						<NavText isActive={activeTab === "history"}>{t("profile")}</NavText>
					</NavItem>
				</IconContainer>
			</BalanceBarContainer>
		</>
	);
};

export default BalanceBar;
