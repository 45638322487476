import React, { createContext, useContext, useState, useEffect } from "react";
import { useModalState } from "../hooks/useModalState";

const GameContext = createContext();

export const GameProvider = ({ children }) => {
	const [game, setGame] = useState(null);
	const { render, active, openModal, closeModal } = useModalState();

	return (
		<GameContext.Provider value={{ game, setGame, render, active, openGame: openModal, closeGame: closeModal }}>
			{children}
		</GameContext.Provider>
	);
};

export const useGame = () => useContext(GameContext);
