import getInitData from "../utils/getInitData";

const serverUrl = process.env.REACT_APP_BACKEND_URL;
const { initDataRaw } = getInitData();

const withdrawFunds = async (recipientAddress, amount, body) => {
	const response = await fetch(`${serverUrl}/api/transactions/withdraw`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${initDataRaw}`,
		},
		body: JSON.stringify({
			recipientAddress,
			amount: parseFloat(amount),
			body: body || "Withdrawal from Casino",
		}),
	});

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	return await response.json();
};

const sendTransaction = async (recipientAddress, amount, body, currency) => {
	const response = await fetch(`${serverUrl}/api/transactions/send`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${initDataRaw}`,
		},
		body: JSON.stringify({
			recipientAddress,
			amount: parseFloat(amount),
			body: body || "Depsit for Casino",
			currency,
		}),
	});

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	return await response.json();
};

export { withdrawFunds, sendTransaction };
