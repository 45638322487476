export const Language = Object.freeze({
	en: "en",
	ru: "ru",
});

const languageMap = {
	[Language.en]: () => import("./translations/en.json"),
	[Language.ru]: () => import("./translations/ru.json"),
};

export async function getTranslation(language) {
	if (!languageMap[language]) {
		console.warn(`Unsupported language: ${language}`);
		return null;
	}

	try {
		const { default: translations } = await languageMap[language]();
		return translations;
	} catch (error) {
		console.error(`Could not load translation for "${language}":`, error);
		return null;
	}
}
