import React, { useEffect, useRef } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { TabsContainer, TabsWrapper, Tab, Icon, Logo, LogoWrap } from "./TabsNavigation.styles";
import useTelegram from "../../hooks/useTelegram";
import { useNavTabs } from "../../contexts/TabsNavigationContext";
import Loader from "../Loader/Loader";
import LogoIcon from "../../assets/logo.png";
import { Language } from "../../utils/i18n/getTranslation";

const TabsNavigation = () => {
	const location = useLocation();
	const activeTabRef = useRef(null);
	const { language } = useTelegram();
	const { type } = useParams();
	const { navItems } = useNavTabs();

	// Скроллинг активного таба
	const scrollToActiveTab = () => {
		if (activeTabRef.current) {
			activeTabRef.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "center",
			});
		}
	};

	useEffect(() => {
		if (navItems.length) {
			scrollToActiveTab();
		}
	}, [navItems, type]);

	const isTabActive = (tab) => {
		return `${location.pathname + location.search}` == tab.to;
	};

	// Рендеринг табов
	const renderTabs = (tabs) =>
		tabs.map((tab) => {
			return (
				<NavLink
					key={tab.id}
					to={tab.to}
					style={{ textDecoration: "none" }}
				>
					{({ isActive }) => {
						const active = isActive && isTabActive(tab);
						return (
							<Tab
								ref={active ? activeTabRef : null}
								isActive={active}
							>
								{tab.icon && (
									<Icon
										src={active && tab.activeIcon ? tab.activeIcon : tab.icon}
										alt={tab.label[language]}
									/>
								)}
								{tab.label[language] || tab.label[Language.en]}
							</Tab>
						);
					}}
				</NavLink>
			);
		});

	return (
		<TabsWrapper>
			<TabsContainer>
				<NavLink to="/">
					<LogoWrap>
						<Logo
							src={LogoIcon}
							alt="Beton Logo"
						/>
					</LogoWrap>
				</NavLink>
				{renderTabs(navItems)}
			</TabsContainer>
		</TabsWrapper>
	);
};

export default TabsNavigation;
