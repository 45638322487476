import React, { useState, useEffect } from "react";
import {
	ProvidersList,
	ProviderItem,
	CheckboxWrapper,
	CheckboxInput,
	CheckboxIcon,
	ProviderLabel,
	ActionButtons,
	Button,
} from "./Provider.styles";
import { useProviders } from "../../contexts/ProvidersContext";
import Providers from "../../constants/Providers";
import { useTranslation } from "../../contexts/TranslationContext";

const ProviderSelector = ({ isOpen, onClose }) => {
	const { t } = useTranslation();
	const allName = t("allProviders");
	const allProviders = [allName, ...Providers.ALL_PROVIDERS];
	const { selectedProviders, updateSelectedProviders, resetProviders } = useProviders();
	const [curSelectedProviders, setCurSelectedProviders] = useState(selectedProviders);

	// Функция для проверки, выбраны ли все провайдеры
	const areAllProvidersSelected = (providers) =>
		Providers.ALL_PROVIDERS.every((provider) => providers.includes(provider));

	const toggleProvider = (providerName) => {
		let updatedProviders;

		if (providerName === allName) {
			if (curSelectedProviders.includes(providerName)) {
				updatedProviders = [];
			} else {
				updatedProviders = allProviders;
			}
		} else if (curSelectedProviders.includes(providerName)) {
			updatedProviders = curSelectedProviders.filter((p) => p !== providerName);
		} else {
			updatedProviders = [...curSelectedProviders, providerName];
		}

		checkAndSetProviders(updatedProviders);
	};

	const isChecked = (providerName) => curSelectedProviders.includes(providerName);

	const checkAndSetProviders = (providers) => {
		if (areAllProvidersSelected(providers)) {
			setCurSelectedProviders([...providers, allName]);
		} else {
			setCurSelectedProviders(providers.filter((p) => p !== allName));
		}
	};

	useEffect(() => {
		checkAndSetProviders(selectedProviders);
	}, [isOpen]);

	const handleSave = () => {
		let finalSelectedProviders = curSelectedProviders.filter((p) => p !== allName);
		updateSelectedProviders(finalSelectedProviders);
		onClose();
	};

	const handleReset = () => {
		resetProviders();
		onClose();
	};

	return (
		<>
			<ProvidersList>
				{allProviders.map((provider) => (
					<ProviderItem
						key={provider}
						onClick={() => toggleProvider(provider)}
					>
						<CheckboxWrapper>
							<CheckboxInput
								type="checkbox"
								checked={isChecked(provider)}
							/>
							<CheckboxIcon checked={isChecked(provider)} />
						</CheckboxWrapper>
						<ProviderLabel>{provider}</ProviderLabel>
					</ProviderItem>
				))}
			</ProvidersList>
			<ActionButtons>
				<Button onClick={handleReset}>{t("reset")}</Button>
				<Button
					primary
					onClick={handleSave}
				>
					{t("show")}
				</Button>
			</ActionButtons>
		</>
	);
};

export default ProviderSelector;
