import React from "react";
import {
	BlockScreenContent,
	BlockScreenContentWrap,
	BlockScreenImage,
	BlockScreenImgeWrap,
	BlockScreenTitle,
	BlockScreenWrapper,
} from "./BlockScreen.style";
import BotQr from "../../assets/BotQr.webp";
import { useTranslation } from "../../contexts/TranslationContext";

const BlockScreen = () => {
	const { t } = useTranslation();

	return (
		<>
			<BlockScreenWrapper>
				<BlockScreenContentWrap>
					<BlockScreenTitle>{t("playOnYourMobile")}</BlockScreenTitle>
					<BlockScreenContent>
						<a
							href="https://t.me/betonproject_bot"
							target="_blank"
							rel="noreferrer"
						>
							<BlockScreenImgeWrap>
								<BlockScreenImage src={BotQr} />
							</BlockScreenImgeWrap>
						</a>
					</BlockScreenContent>
				</BlockScreenContentWrap>
			</BlockScreenWrapper>
		</>
	);
};

export default BlockScreen;
