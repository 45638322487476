import styled from "styled-components";
import { colors } from "../../styles/Variables.style";
import LikeOutlineIcon from "../../assets/likeOutline.svg";
import LikeActiveIcon from "../../assets/likeActive.svg";

export const GameContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${colors.bg};
	padding: 16px 16px 0px;
	gap: 16px;
	height: 100%;
	width: 100%;
	position: relative;
`;

export const GameHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

export const GameTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	justify-content: space-between;
	width: 100%;
`;

export const GameTitle = styled.h2`
	font-size: 22px;
	font-weight: 800;
	line-height: 30px;
	text-align: left;
`;

export const GameProvider = styled.p`
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
	color: white;
	opacity: 0.6;
`;

export const GameImage = styled.img`
	max-width: calc(100vw - 32px);
	max-height: 60vh;
	height: auto;
	border-radius: 12px;
	aspect-ratio: 3/4;
	margin: auto 0;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
	justify-content: center;
	width: 100%;
	padding-bottom: 32px;
`;

export const DemoButton = styled.button`
	flex: 1;
	padding: 11px 16px;
	background: rgba(255, 255, 255, 0.06);
	color: white;
	border: none;
	border-radius: 12px;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: center;
	cursor: pointer;
	transition: transform 0.3s ease;

	&:hover {
		transform: scale(1.05);
	}
`;

export const PlayButton = styled(DemoButton)`
	background-color: ${colors.white};
	color: black;
	box-shadow: 0 2px 0 0 ${colors.blue};
`;

export const ErrorMessage = styled.p`
	color: red;
	font-size: 0.9rem;
	margin-bottom: 8px;
`;

export const LikeButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0vh 2px 0vh 0vh ${colors.blue};
	min-width: 40px;
	min-height: 40px;
	background: #ffffff0f;
	align-self: flex-start;
	margin-top: 0;
`;

export const LikeIcon = styled.div`
	width: 20px;
	height: 20px;
	background: url(${({ isLiked }) => (isLiked ? LikeActiveIcon : LikeOutlineIcon)}) no-repeat center center;
	background-size: contain;
`;
