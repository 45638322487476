import getInitData from "../utils/getInitData";

const useTelegram = () => {
	const { initData, platform } = getInitData();
	const isMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
		!["unknown", "tdesktop"].includes(platform);

	const language = navigator.language?.split("-")[0] || initData?.user?.languageCode || "en";

	return {
		language,
		user: initData?.user || {},
		isMobile: Boolean(process.env.REACT_APP_DEV_TELEGRAM_INIT_DATA) || isMobile,
	};
};

export default useTelegram;
