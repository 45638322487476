import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { getMinDeposit, getRemainingWagering, getUserBalance } from "../services/userService";
import { Currency } from "../constants/Currency";

const WalletInfoContext = createContext();

export const WalletInfoProvider = ({ children }) => {
	const [balance, setBalance] = useState(null);
	const [minWithdraw, setMinWithdraw] = useState(1.62);
	const [minDeposit, setMinDeposit] = useState(1.62);
	const [remainingWagering, setRemainingWagering] = useState(0);
	const [courses, setCourses] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const fetchTonCourse = useCallback(async () => {
		try {
			const result = await fetch(
				"https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd",
			);
			const data = await result.json();
			const tonToUsd = data["the-open-network"].usd; // Сколько USD за 1 TON
			const usdToTon = tonToUsd ? (1 / tonToUsd).toFixed(2) : "0"; // Сокращаем до 2 знаков

			setCourses({ ...courses, [Currency.TON]: parseFloat(usdToTon) }); // Преобразуем обратно в число
		} catch (error) {
			console.error("Ошибка при получении курса TON:", error);
		}
	}, []);

	const fetchMinDeposit = useCallback(async () => {
		try {
			const result = await getMinDeposit();
			setMinDeposit(result.minDeposit);
			setMinWithdraw(result.minWithdraw);
		} catch (error) {
			console.error("Ошибка при получении минимального депозита:", error);
		}
	}, []);

	const fetchRemainingWagering = useCallback(async () => {
		try {
			const remaining = await getRemainingWagering();
			setRemainingWagering(remaining);
		} catch (error) {
			console.error("Error fetching remaining wagering:", error);
		}
	}, []);

	const fetchBalance = useCallback(async () => {
		try {
			const response = await getUserBalance();
			const fetchedBalance = parseFloat(response.balance);
			if (isNaN(fetchedBalance)) {
				setBalance(0);
			} else {
				setBalance(fetchedBalance);
			}
		} catch (error) {
			console.error("Ошибка при загрузке баланса:", error);
			setBalance(0);
		}
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			await Promise.all([fetchBalance(), fetchRemainingWagering(), fetchMinDeposit(), fetchTonCourse()]);

			setIsLoading(false);
		};

		fetchData();
	}, []);

	const calcMinDeposit = useCallback(
		(currencyKey) => {
			if (currencyKey === "USDT") return minDeposit;

			const result = minDeposit * courses[Currency[currencyKey]];
			return Math.ceil(result * 100) / 100;
		},
		[minDeposit, courses],
	);

	return (
		<WalletInfoContext.Provider
			value={{ isLoading, balance, calcMinDeposit, minWithdraw, remainingWagering, courses, fetchBalance }}
		>
			{children}
		</WalletInfoContext.Provider>
	);
};

export const useWalletInfo = () => useContext(WalletInfoContext);
