import React, { useEffect } from "react";
import { Container, Title } from "./Tournaments.style";
import useBackButton from "../../hooks/useBackButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationContext";

const Tournaments = () => {
	const BackButton = useBackButton();
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		const onBack = () => navigate(-1);
		BackButton.onClickCstm(onBack);
		return () => {
			BackButton.offClickCstm(onBack);
		};
	}, [navigate]);

	return (
		<Container>
			<Title>{t("comingSoon")}</Title>
		</Container>
	);
};

export default Tournaments;
