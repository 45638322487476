import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import GameTypes from "../constants/GameTypes";
import { gamesOnPageLimit, transactionsOnPageLimit } from "../constants/Settings";
import getInitData from "../utils/getInitData";
const serverUrl = process.env.REACT_APP_BACKEND_URL;

const { initDataRaw, initData } = getInitData();

const fetchWithAuth = async (url, options = {}) => {
	const headers = {
		Accept: "application/json",
		Authorization: `Bearer ${initDataRaw}`,
		...options.headers,
	};

	let response = await fetch(serverUrl + url, { ...options, headers });

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	return await response.json();
};

const getUserBalance = async () => {
	return await fetchWithAuth(`/api/users/balance`, {});
};

const verifyTransaction = async (boc, senderAddress, amount, transactionType, currency) => {
	try {
		return await fetchWithAuth(`/api/transactions/verify-transaction`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ boc, senderAddress, amount, transactionType, currency }),
		});
	} catch (error) {
		console.error("Ошибка при верификации транзакции:", error);
		throw new Error("Ошибка верификации транзакции");
	}
};

const getMinDeposit = async () => {
	return await fetchWithAuth(`/api/users/min-deposit`, {});
};

const startBgamingGame = async (gameId, language, isDemo) => {
	const url = isDemo ? "/demo" : "/sessions";
	const data = await fetchWithAuth(url, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ gameIdentifier: gameId, language }),
	});

	return data.gameUrl;
};

const startZephyrGame = async (gameId, isDemo) => {
	const data = await fetchWithAuth(`/game/${gameId}?demo=${isDemo}`, {});
	return data.url;
};

const getRemainingWagering = async () => {
	const data = await fetchWithAuth(`/api/users/get-wagering`, {});
	return data.remainingWagering;
};

const addToFavorites = async (gameId, provider, name, src) => {
	return await fetchWithAuth(`/api/users/favorites`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ gameId, provider, name, src }),
	});
};

const removeFromFavorites = async (gameId) => {
	return await fetchWithAuth(`/api/users/favorites/${gameId}`, {
		method: "DELETE",
	});
};

const getTransactions = async (filter, page, signal) => {
	const params = new URLSearchParams();

	if (filter !== "all") {
		params.append("type", filter);
	}

	params.append("page", page);
	params.append("limit", transactionsOnPageLimit);
	const queryString = params.toString();

	return await fetchWithAuth(`/api/transactions/history?${queryString}`, { signal });
};

const useTransactions = (filter) => {
	return useInfiniteQuery({
		queryKey: ["transactions", filter],
		queryFn: ({ pageParam = 1, signal }) => getTransactions(filter, pageParam, signal),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.totalPages > allPages.length ? allPages.length + 1 : undefined;
		},
		enabled: !!filter,
	});
};

const getNavigationMenuItems = async () => {
	return await fetchWithAuth(`/api/users/navigation-menu`, {});
};

const getNavigationMenuItemById = async (id) => {
	return await fetchWithAuth(`/api/users/navigation-menu/${id}`, {});
};

const searchGames = async (query, signal) => {
	return await fetchWithAuth(`/api/users/searchGames?search=${query}`, { signal });
};

const useSearchGames = (query) => {
	return useQuery({
		queryKey: ["searchGames", query],
		queryFn: ({ signal }) => searchGames(query, signal),
		enabled: !!query,
		retry: false,
	});
};

const getGames = async (type, providers, category, page = 1, signal) => {
	const params = new URLSearchParams();

	if (providers) {
		if (Array.isArray(providers)) {
			const providersString = providers.join(",");
			params.append("provider", providersString);
		} else {
			params.append("provider", providers);
		}
	}

	if (category && type === GameTypes.NAVIGATION) {
		params.append("category", category);
	}

	params.append("page", page);
	params.append("limit", gamesOnPageLimit);

	const queryString = params.toString();
	const url = `/api/users/games/${type}${queryString ? `?${queryString}` : ""}`;

	return await fetchWithAuth(url, { signal });
};

const useGames = (type, providers, category) => {
	return useInfiniteQuery({
		queryKey: ["games", type, providers, category],
		queryFn: ({ pageParam = 1, signal }) => getGames(type, providers, category, pageParam, signal),
		getNextPageParam: (lastPage, allPages) => {
			// Проверяем, есть ли еще страницы для загрузки
			return lastPage.totalPages > allPages.length ? allPages.length + 1 : undefined;
		},
		enabled: !!type,
	});
};

const getRandomGame = async () => {
	return await fetchWithAuth(`/api/users/randomGame`, {});
};

const sendUtmMark = async () => {
	if (initData.startParam) {
		try {
			return await fetchWithAuth(`/api/users/utm/increment`, {
				method: "POST",
			});
		} catch (error) {
			console.error(error);
		}
	}
};

export {
	getUserBalance,
	startBgamingGame,
	startZephyrGame,
	getMinDeposit,
	getRemainingWagering,
	removeFromFavorites,
	addToFavorites,
	verifyTransaction,
	getNavigationMenuItems,
	getNavigationMenuItemById,
	useSearchGames,
	useTransactions,
	useGames,
	getRandomGame,
	sendUtmMark,
};
