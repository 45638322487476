import React, { useEffect } from "react";
import { DrawerMiniContent, DrawerMiniEl, DrawerMiniTitle, DrawerMiniTitleWrap, Overlay } from "./DrawerMini.style";

const DrawerMini = ({ isOpen, onClose, title, children }) => {
	return (
		<>
			<Overlay
				isOpen={isOpen}
				onClick={() => onClose()}
			/>
			<DrawerMiniEl isOpen={isOpen}>
				<DrawerMiniContent>
					<DrawerMiniTitleWrap>
						<DrawerMiniTitle>{title}</DrawerMiniTitle>
					</DrawerMiniTitleWrap>
					{children}
				</DrawerMiniContent>
			</DrawerMiniEl>
		</>
	);
};

export default DrawerMini;
