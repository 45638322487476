import styled from "styled-components";
import { colors } from "../../styles/Variables.style";

export const BlockScreenWrapper = styled.div`
	position: fixed;
	z-index: 101;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background: ${colors.bg};
`;

export const BlockScreenTitle = styled.h2`
	font-size: 26px;
	font-weight: 800;
	padding: 0 30px;
`;

export const BlockScreenContentWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 16px;
	text-align: center;
`;

export const BlockScreenContent = styled.div`
	width: 100%;
	padding: 0 30px;
	max-width: 400px;
`;

export const BlockScreenImgeWrap = styled.div`
	position: relative;
	width: 100%;
	padding-top: 100%;
	border-radius: 16px;
`;

export const BlockScreenImage = styled.img`
	border-radius: inherit;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
`;
