import React, { useEffect } from "react";
import { DrawerContent, DrawerEl, DrawerTitle, DrawerTitleWrap } from "./Drawer.style";
import useBackButton from "../../hooks/useBackButton";

const Drawer = ({ isOpen, onClose, title, zIndex, direction, isBackButton = true, children }) => {
	const BackButton = useBackButton();

	useEffect(() => {
		if (!isBackButton) return;
		const onBack = () => onClose();
		if (isOpen) {
			BackButton.onClickCstm(onBack);
		}
		return () => {
			BackButton.offClickCstm(onBack);
		};
	}, [isOpen]);

	return (
		<DrawerEl
			isOpen={isOpen}
			zIndex={zIndex}
			direction={direction}
		>
			<DrawerContent>
				<DrawerTitleWrap isTitle={title}>
					<DrawerTitle>{title}</DrawerTitle>
				</DrawerTitleWrap>
				{children}
			</DrawerContent>
		</DrawerEl>
	);
};

export default Drawer;
