import styled from "styled-components";
import { sizes } from "../../styles/Variables.style";
import Banner2 from "../../assets/Banner2.webp";

export const MainContainer = styled.div``;

export const FixedBackground = styled.div`
	width: 100%;
	height: 26.3vh;
	background-image: url(${Banner2});
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
`;

export const GradientGrid = styled.div`
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
	margin-top: 8px;
`;

export const GamesWrap = styled.div`
	padding: 16px;
`;

export const WhiteBarWithDots = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	height: 4px;
	margin: 0 auto;
	margin-top: 8px;
`;
