import React from "react";
import { ProviderContainer, ProviderButton, RemoveIcon, ResetButton } from "./SelectedProviders.style";
import CloseDark from "../../assets/close-dark.svg";
import { useTranslation } from "../../contexts/TranslationContext";

const SelectedProviders = ({ selectedProviders, onRemoveProvider, onResetProviders }) => {
	const { t } = useTranslation();
	return (
		<ProviderContainer>
			{selectedProviders.map((provider) => (
				<ProviderButton key={provider}>
					{provider}
					<RemoveIcon
						src={CloseDark}
						alt="Удалить"
						onClick={() => onRemoveProvider(provider)}
					/>
				</ProviderButton>
			))}
			{selectedProviders.length > 0 && <ResetButton onClick={() => onResetProviders()}>{t("resetAll")}</ResetButton>}
		</ProviderContainer>
	);
};

export default SelectedProviders;
