import React, { useState, useEffect } from "react";
import { FixedBackground, WhiteBarWithDots, GradientGrid, MainContainer, GamesWrap } from "./Main.styles";
import Footer from "../../components/Footer/Footer";
import TabsNavigation from "../../components/TabsNavigation/TabsNavigation";
import GameTypes from "../../constants/GameTypes";
import { useProviders } from "../../contexts/ProvidersContext";
import useTelegram from "../../hooks/useTelegram";
import { SectionTitle } from "../../styles/Shared.style";
import GamesSection from "../../components/GamesSection/GamesSection";
import { useTranslation } from "../../contexts/TranslationContext";

const Main = () => {
	const { selectedProviders } = useProviders();
	const { t } = useTranslation();

	return (
		<MainContainer>
			<FixedBackground />
			<WhiteBarWithDots />
			<TabsNavigation />
			<GradientGrid>
				<SectionTitle>{t("popular")}</SectionTitle>
				<GamesSection
					gameType={GameTypes.POPULAR}
					providers={selectedProviders}
					horizontal
				/>
			</GradientGrid>
			<GradientGrid>
				<SectionTitle>{t("new")}</SectionTitle>
				<GamesSection
					gameType={GameTypes.NEW}
					providers={selectedProviders}
					horizontal
				/>
			</GradientGrid>

			<Footer />
		</MainContainer>
	);
};

export default Main;
