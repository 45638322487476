import styled, { css } from "styled-components";
import { skeletonAnimation } from "../../../styles/Shared.style";

export const Dropdown = styled.div`
	position: absolute;
	top: calc(100% + 16px);
	left: 0;
	width: 100%;
	background: linear-gradient(
			99.31deg,
			rgba(13, 16, 22, 0.5) -9.79%,
			rgba(17, 30, 54, 0.8) 20.84%,
			rgba(13, 16, 22, 0.5) 94.54%
		),
		linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.8) 5.44%, rgba(13, 16, 22, 0) 93.15%),
		url("/Main-Page/Footer/logo.png") no-repeat center;

	backdrop-filter: blur(2vh);
	-webkit-backdrop-filter: blur(2vh);
	background-size: contain;
	background-position: center;
	border-radius: 10px;
	overflow-y: auto;
	max-height: 230px;
	z-index: 100;

	opacity: ${({ isDropdownOpen }) => (isDropdownOpen ? "1" : "0")};
	transform: ${({ isDropdownOpen }) => (isDropdownOpen ? "translateY(0)" : "translateY(-15px)")};
	visibility: ${({ isDropdownOpen }) => (isDropdownOpen ? "visible" : "hidden")};
	pointer-events: ${({ isDropdownOpen }) => (isDropdownOpen ? "auto" : "none")};
	transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
`;

export const DropdownItem = styled.div`
	padding: 12px;
	font-size: 18px;
	line-height: 25px;
	color: white;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.45);

	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}

	&:not(:last-child) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}
`;

export const DropdownItemLoading = styled.div`
	border-radius: 8px;
	height: 25px;
	${skeletonAnimation};
`;
