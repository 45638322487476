import styled from "styled-components";

export const CurrencyIconWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const CurrencyIconEl = styled.img`
	width: 20px;
	height: 20px;
`;
