import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import TelegramInit from "./utils/TelegramInit";
import mixpanel from "mixpanel-browser";
import getInitData from "./utils/getInitData";

const container = document.getElementById("root");
const root = createRoot(container);

TelegramInit();
const { initData } = getInitData();
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.identify(initData?.user?.id || "unknown");

root.render(
	// <React.StrictMode>
	<App />,
	// </React.StrictMode>
);
