import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { Language, getTranslation } from "../utils/i18n/getTranslation";
import useTelegram from "../hooks/useTelegram";

const TranslationContext = createContext(null);

export const TranslationProvider = ({ children }) => {
	const { language } = useTelegram();
	const [translations, setTranslations] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const defaultLanguage = Language.en;

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		const loadTranslations = async (lang) => {
			try {
				const data = await getTranslation(lang);
				if (isMounted && data) {
					setTranslations(data);
				} else {
					console.warn(`Failed to load translations for language "${lang}", falling back to "${defaultLanguage}"`);
					const fallbackData = await getTranslation(defaultLanguage);
					if (isMounted && fallbackData) {
						setTranslations(fallbackData);
					}
				}
			} catch (error) {
				console.error(`Error loading translations for language "${lang}"`, error);
				const fallbackData = await getTranslation(defaultLanguage);
				if (isMounted && fallbackData) {
					setTranslations(fallbackData);
				}
			} finally {
				setIsLoading(false);
			}
		};

		loadTranslations(language);

		return () => {
			isMounted = false;
		};
	}, [language]);

	const translate = useCallback(
		(key, ...params) => {
			const translationKey = translations[key];

			if (!translationKey) {
				console.warn(`No translation for key "${key}" in language ${language}`);
				return key;
			}

			return translationKey.replace(/\{(\d+)}/g, (_, index) => params[index]);
		},
		[language, translations],
	);

	const translatePlural = useCallback(
		(key, count) => {
			if (!translations) return key;

			if (language === "en") {
				const translationKey = count === 1 ? translations[key] : translations[`${key}_plural`];
				return translationKey ? translationKey.replace("{count}", count) : key;
			}

			if (language === "ru") {
				let suffix = "_2"; // множественное число по умолчанию

				if (count % 10 === 1 && count !== 11) {
					suffix = "_0"; // единственное число
				} else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count)) {
					suffix = "_1"; // несколько предметов
				}

				const translationKey = translations[`${key}${suffix}`];
				return translationKey ? translationKey.replace("{count}", count) : key;
			}

			console.warn(`Unsupported language pluralization: ${language}`);
			return key;
		},
		[language, translations],
	);

	return (
		<TranslationContext.Provider value={{ t: translate, tPlural: translatePlural, isLoading }}>
			{children}
		</TranslationContext.Provider>
	);
};

export const useTranslation = () => useContext(TranslationContext);
