import React, { createContext, useContext, useState, useEffect } from "react";
import { getNavigationMenuItems } from "../services/userService";
import GameTypes from "../constants/GameTypes";
import HistoryIcon from "../assets/history.svg";
import HistoryDarkIcon from "../assets/historyDark.svg";
import LikeIcon from "../assets/like.svg";
import LikeDarkIcon from "../assets/likeDark.svg";
import { useTranslation } from "./TranslationContext";

const TabsNavigationContext = createContext();

export const TabsProvider = ({ children }) => {
	const { t, isLoading: isTranlationLoading } = useTranslation();
	const [navItems, setNavItems] = useState([]);

	const staticTabs = [
		{
			id: GameTypes.POPULAR,
			to: `/games/${GameTypes.POPULAR}`,
			labelKey: "popular",
		},
		{
			id: GameTypes.NEW,
			to: `/games/${GameTypes.NEW}`,
			labelKey: "new",
		},
		{ id: "dynamic", dynamic: true },
		{
			id: GameTypes.HISTORY,
			to: `/games/${GameTypes.HISTORY}`,
			labelKey: "history",
			icon: HistoryIcon,
			activeIcon: HistoryDarkIcon,
		},
		{
			id: GameTypes.FAVOURITE,
			to: `/games/${GameTypes.FAVOURITE}`,
			labelKey: "favourites",
			icon: LikeIcon,
			activeIcon: LikeDarkIcon,
		},
	];

	useEffect(() => {
		if (isTranlationLoading) return;
		const fetchNavItems = async () => {
			try {
				const response = await getNavigationMenuItems();
				const dynamicTabs = response.map((item) => ({
					id: item.id,
					to: `/games/${GameTypes.NAVIGATION}?tab-id=${item.id}`,
					label: { ru: item.name_ru, en: item.name_en }, // Оставляем переводы от бэка
					icon: item.icon_inactive && process.env.REACT_APP_BACKEND_URL + item.icon_inactive,
					activeIcon: item.icon_active && process.env.REACT_APP_BACKEND_URL + item.icon_active,
					provider: item.provider,
					category: item.game_category,
				}));

				// Добавляем переводы для статических табов
				const translatedStaticTabs = staticTabs.map((tab) =>
					tab.dynamic
						? tab
						: {
								...tab,
								label: {
									ru: t(tab.labelKey, "ru"),
									en: t(tab.labelKey, "en"),
								},
						  },
				);

				// Объединяем все табы
				const combinedTabs = translatedStaticTabs.flatMap((tab) => (tab.dynamic ? dynamicTabs : tab));

				setNavItems(combinedTabs);
			} catch (error) {
				console.error("Error fetching nav items:", error);
				setNavItems(
					staticTabs
						.filter((tab) => !tab.dynamic)
						.map((tab) => ({
							...tab,
							label: {
								ru: t(tab.labelKey, "ru"),
								en: t(tab.labelKey, "en"),
							},
						})),
				);
			}
		};

		fetchNavItems();
	}, [t, isTranlationLoading]);

	return <TabsNavigationContext.Provider value={{ navItems }}>{children}</TabsNavigationContext.Provider>;
};

export const useNavTabs = () => useContext(TabsNavigationContext);
