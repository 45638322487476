import styled from "styled-components";
import { colors } from "../../styles/Variables.style";
import { ScrollContainer } from "../../styles/Containers.style";
import CheckNo from "../../assets/checkNo.svg";
import CheckYes from "../../assets/checkYes.svg";

export const ProvidersList = styled(ScrollContainer)`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 16px;
`;

export const ProviderItem = styled.div`
	display: flex;
	align-items: center;
	padding: 16px 0;
	&:not(:first-child) {
		border-top: 1px solid ${colors.darkGray};
	}
`;

export const CheckboxWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

export const CheckboxInput = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
`;

export const CheckboxIcon = styled.div`
	width: 20px;
	height: 20px;
	background-image: url(${({ checked }) => (checked ? CheckYes : CheckNo)});
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ProviderLabel = styled.label`
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;

	color: white;
	margin-left: 8px;
`;

export const ActionButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
	padding: 0 16px 32px;
	align-items: center;
`;

export const Button = styled.button`
	background-color: ${({ primary }) => (primary ? colors.white : colors.darkGray)};
	opacity: 1;
	color: ${({ primary }) => (primary ? "black" : colors.white)};
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	padding: 11px 16px;

	border: none;
	cursor: pointer;
	flex: 1;
	border-radius: 12px;
	box-shadow: ${({ primary }) => (primary ? `0px 2px 0px 0px ${colors.blue}` : "none")};
	transition: all 0.3s ease;
	&:hover {
		scale: 1.05;
	}
`;
