import React, { useState, useEffect, useRef, useCallback } from "react";
import {
	ErrorMessage,
	ErrorWrap,
	GameCard,
	GameImage,
	GamesGridEl,
	GamesGridWrap,
	LoadingGame,
	LoadingTrigger,
	NoGamesMessage,
} from "./GamesGrid.style";
import { gamesOnPageLimit } from "../../constants/Settings";
import { useGame } from "../../contexts/GameContext";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { RetryButton } from "../../styles/Shared.style";
import { useTranslation } from "../../contexts/TranslationContext";

const GamesGrid = ({ games, errorMessage, isLoading, isError, hasNextPage, loadMore, onRetry, horizontal }) => {
	const { setGame, openGame } = useGame();
	const loadMoreRef = useRef(null);
	const wrapRef = useRef(null);
	const { t } = useTranslation();

	// Состояние для отслеживания загрузки изображений
	const [imageLoaded, setImageLoaded] = useState({});

	const handleStartGame = (game) => {
		setGame(game);
		openGame();
	};

	const handleImageLoad = (gameId) => {
		setImageLoaded((prevState) => ({
			...prevState,
			[gameId]: true, // Обновляем состояние, когда изображение загружено
		}));
	};

	const renderGames = (games) => {
		return games.map((game) => (
			<GameCard
				key={game.id}
				onClick={() => handleStartGame(game)}
			>
				{!imageLoaded[game.id] && <LoadingGame />} {/* Показываем LoadingGame, пока изображение не загружено */}
				<GameImage
					src={`${process.env.REACT_APP_BACKEND_URL}${game.src}` || "/path/to/default-image.png"}
					alt={game.name || "Game"}
					onLoad={() => handleImageLoad(game.id)} // Вызываем handleImageLoad, когда изображение загружено
				/>
			</GameCard>
		));
	};

	const renderSkeleton = () => {
		return Array.from({ length: gamesOnPageLimit }).map((_, index) => (
			<GameCard key={index}>
				<LoadingGame />
			</GameCard>
		));
	};

	const observerCallback = useCallback(
		(entries) => {
			const target = entries[0];
			if (target.isIntersecting && hasNextPage && !isLoading) {
				loadMore();
			}
		},
		[hasNextPage, isLoading, loadMore],
	);

	useIntersectionObserver(observerCallback, wrapRef, loadMoreRef, {
		root: horizontal ? wrapRef.current : null,
		rootMargin: horizontal ? "0px 200px 0px 0px" : "0px 0px 200px 0px",
	});

	return (
		<>
			<GamesGridWrap
				ref={wrapRef}
				isHorizontal={horizontal}
			>
				<GamesGridEl isHorizontal={horizontal}>
					{games?.length > 0 && renderGames(games)}
					{(!games || games.length === 0) && !isLoading && (
						<NoGamesMessage>
							{t("games.noItems")}
							<div>{errorMessage ? errorMessage : t("games.noGames")}</div>
						</NoGamesMessage>
					)}

					{isError && (
						<ErrorWrap isHorizontal={horizontal}>
							<RetryButton onClick={onRetry}>{t("retry")}</RetryButton>
						</ErrorWrap>
					)}

					{isLoading && renderSkeleton()}
					<LoadingTrigger
						isHorizontal={horizontal}
						ref={loadMoreRef}
					/>
				</GamesGridEl>
			</GamesGridWrap>
		</>
	);
};

export default GamesGrid;
