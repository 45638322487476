import React, { useState } from "react";
import { ProvidersButton, ProvidersButtonWrap, ProvidersIcon, SelectedProvidersCount } from "./ProvidersSection.style";
import SelectedProviders from "../SelectedProviders/SelectedProviders";
import { useProviders } from "../../contexts/ProvidersContext";
import ProviderSelector from "../Providers/Provider";
import Drawer from "../Drawer/Drawer";
import ArrowDown from "../../assets/arrow-bottom.svg";
import { useModalState } from "../../hooks/useModalState";
import { useTranslation } from "../../contexts/TranslationContext";

const ProvidersSection = () => {
	const { selectedProviders, resetProviders, removeProvider } = useProviders();
	const { t } = useTranslation();
	const { render, active, openModal, closeModal } = useModalState();

	return (
		<>
			<ProvidersButtonWrap>
				<ProvidersButton onClick={() => openModal()}>
					{t("allProviders")}
					{selectedProviders.length > 0 && <SelectedProvidersCount>{selectedProviders.length}</SelectedProvidersCount>}
					<ProvidersIcon
						src={ArrowDown}
						alt="Arrow"
					/>
				</ProvidersButton>
			</ProvidersButtonWrap>

			{selectedProviders.length > 0 && (
				<SelectedProviders
					selectedProviders={selectedProviders}
					onRemoveProvider={removeProvider}
					onResetProviders={resetProviders}
				/>
			)}

			{render && (
				<Drawer
					isOpen={active}
					onClose={closeModal}
					title={t("allProviders")}
				>
					<ProviderSelector
						isOpen={active}
						onClose={closeModal}
					/>
				</Drawer>
			)}
		</>
	);
};

export default ProvidersSection;
