import styled from "styled-components";
import { colors, sizes } from "../../styles/Variables.style";

export const FooterContainer = styled.footer`
	width: 100%;
	text-align: center;
	position: relative;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 16vh;
`;

export const FooterLogo = styled.img`
	width: 100%;
	display: block;
	margin: 0 auto 16px;
`;

export const WarnImage = styled.img`
	width: 50px;
	height: 50px;
	margin: 16px 0;
	display: block;
`;

export const SupportButtons = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
	max-width: 100%;
	padding: 0 ${sizes.padding};
`;

export const SupportButton = styled.div`
	background-color: ${colors.darkGray};
	border-radius: 12px;
	padding: 8px 16px;
	display: flex;
	gap: 8px;
	font-size: 14px;
	cursor: pointer;
	flex: 1; /* Кнопки займут равное место */

	box-shadow: 0 2px 0 0 ${colors.blue};
	transition: transform 0.3s ease;

	&:hover {
		transform: scale(1.05);
	}

	div {
		display: flex;
		justify-content: center;
		flex-direction: column;
		overflow: hidden; /* Обрезаем текст, чтобы он не выходил за границы */
		text-overflow: ellipsis; /* Добавляем многоточие, если текст не помещается */
	}

	span {
		font-size: 10px;
		text-align: left;
		color: #bbb;
		font-weight: 400;
	}
`;

export const ButtonTitle = styled.h2`
	text-align: left;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
`;

export const SupportIcon = styled.img`
	width: 20px;
	height: 20px;
`;

export const DocumentLinks = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	width: 100%;
	padding: 48px ${sizes.padding} 0;
`;

export const DocumentLink = styled.a`
	color: ${colors.white};
	text-decoration: none;
	font-size: 14px;

	display: flex;
	justify-content: space-between;
	width: 100%;

	&:hover {
		text-decoration: underline;
	}
`;

export const ArrowIcon = styled.img`
	width: 18px;
	height: 18px;
`;

export const FooterText = styled.p`
	font-size: 10px;

	padding: 0 ${sizes.padding};
	line-height: 14px;
	opacity: 0.6;
	text-align: left;
`;
