import { backButton } from "@telegram-apps/sdk";

const useBackButton = () => {
	return {
		...backButton,
		onClickCstm(callback) {
			if (!backButton.isSupported()) return;
			backButton.onClick(callback);
			if (!backButton.isVisible()) {
				backButton.show();
			}
		},
		offClickCstm(callback) {
			if (!backButton.isSupported()) return;
			backButton.offClick(callback);
			if (backButton.isVisible()) {
				backButton.hide();
			}
		},
	};
};

export default useBackButton;
