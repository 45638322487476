import React from "react";
import { CurrencyIconEl, CurrencyIconWrap } from "./CurrencyIcon.style";
import UsdtIcon from "../../../assets/Wallet/usdt.svg";
import TonIcon from "../../../assets/Wallet/ton.svg";
import { Currency } from "../../../constants/Currency";

const iconMap = {
	[Currency.USDT]: UsdtIcon,
	[Currency.TON]: TonIcon,
};

const CurrencyIcon = ({ withName, currencyVal }) => {
	return (
		<CurrencyIconWrap>
			<CurrencyIconEl
				src={iconMap[currencyVal]}
				alt={currencyVal}
			/>
			{withName && <span>{currencyVal}</span>}
		</CurrencyIconWrap>
	);
};

export default CurrencyIcon;
