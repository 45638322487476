import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Main from "./pages/Main/Main";
import BalanceBar from "./components/BalanceBar/BalanceBar";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import Tournaments from "./pages/Tournaments/Tournaments";
import { backButton, defineEventHandlers, on } from "@telegram-apps/sdk";
import trackMixpanel from "./utils/trackEvent";
import Games from "./pages/Games/Games";
import { ProvidersProvider } from "./contexts/ProvidersContext";
import { GlobalStyles } from "./styles/GlobalStyles.style";
import { AppContainer } from "./styles/Containers.style";
import { TabsProvider, useNavTabs } from "./contexts/TabsNavigationContext";
import Drawer from "./components/Drawer/Drawer";
import { GameProvider, useGame } from "./contexts/GameContext";
import Game from "./components/Game/Game";
import { useModalState } from "./hooks/useModalState";
import SearchGames from "./components/Search/Search";
import useTelegram from "./hooks/useTelegram";
import Wallet from "./components/Wallet/Wallet";
import { WalletInfoProvider, useWalletInfo } from "./contexts/WalletInfoContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BlockScreen from "./components/BlockScreen/BlockScreen";
import { TranslationProvider, useTranslation } from "./contexts/TranslationContext";
import Loader from "./components/Loader/Loader";
import { sendUtmMark } from "./services/userService";
import useBackButton from "./hooks/useBackButton";

const queryClient = new QueryClient();

const AppContentWithRouter = () => {
	const { render: renderGame, active: gameActive, closeGame } = useGame();
	const {
		render: renderSearch,
		active: searchActive,
		openModal: openSearch,
		closeModal: closeSearch,
	} = useModalState();
	const {
		render: renderWallet,
		active: walletActive,
		openModal: openWallet,
		closeModal: closeWallet,
	} = useModalState();
	const { isMobile } = useTelegram();
	const { t, isLoading: isTranlationLoading } = useTranslation();
	const { navItems } = useNavTabs();
	const { isLoading: isWalletInfoLoading } = useWalletInfo();
	const location = useLocation();
	const BackButton = useBackButton();

	useEffect(() => {
		BackButton.offClickCstm();
		const modalNameUrl = new URLSearchParams(location.search).get("modal");
		switch (modalNameUrl) {
			case "wallet":
				openWallet();
				break;
			default:
				break;
		}

		sendUtmMark();

		trackMixpanel("Session Started");

		defineEventHandlers();

		const removeVisibilityListener = on("visibility_changed", (e) => {
			if (!e.is_visible) {
				trackMixpanel("App Minimized");
			}
		});

		return () => {
			removeVisibilityListener();
		};
	}, [location.search]);

	if (!navItems.length || isTranlationLoading || isWalletInfoLoading) return <Loader />;

	return (
		<>
			{isMobile ? (
				<AppContainer>
					<Routes>
						<Route
							path="/"
							element={<Main />}
						/>
						<Route
							path="/games/:type"
							element={<Games />}
						/>
						<Route
							path="/tournaments"
							element={<Tournaments />}
						/>
					</Routes>
					<BalanceBar
						openSearch={openSearch}
						openWallet={openWallet}
					/>
					{renderGame && (
						<Drawer
							isOpen={gameActive}
							onClose={closeGame}
							zIndex={102}
						>
							<Game />
						</Drawer>
					)}
					{renderSearch && (
						<Drawer
							isOpen={searchActive}
							onClose={closeSearch}
							title={t("search.searchGames")}
						>
							<SearchGames />
						</Drawer>
					)}
					{renderWallet && (
						<Drawer
							isOpen={walletActive}
							onClose={closeWallet}
						>
							<Wallet onClose={closeWallet} />
						</Drawer>
					)}
				</AppContainer>
			) : (
				<BlockScreen />
			)}
		</>
	);
};

const App = () => {
	return (
		<TonConnectUIProvider
			manifestUrl="https://betongame.club/tonconnect-manifest.json"
			enableAndroidBackHandler={false}
			uiPreferences={{
				logLevel: "silent",
				theme: THEME.DARK,
			}}
		>
			<QueryClientProvider client={queryClient}>
				<TranslationProvider>
					<ProvidersProvider>
						<TabsProvider>
							<GameProvider>
								<WalletInfoProvider>
									<Router>
										<AppContentWithRouter />
									</Router>
								</WalletInfoProvider>
							</GameProvider>
						</TabsProvider>
					</ProvidersProvider>
				</TranslationProvider>
			</QueryClientProvider>
			<GlobalStyles />
		</TonConnectUIProvider>
	);
};

export default App;
