import React from "react";
import {
	WalletOptionsContainer,
	WalletInfoOption,
	WalletNote,
	Icon,
	WalletOption,
	OptionTitle,
	WalletAddress,
} from "./WalletManage.style";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useTranslation } from "../../../contexts/TranslationContext";
import ArrowRightIcon from "../../../assets/arrow-right.svg";
import RefreshIcon from "../../../assets/refresh.svg";
import { shortenAddress } from "../../../utils/helpers/shortenAddress";

const WalletManage = ({ handleConnectWallet }) => {
	const wallet = useTonAddress();
	const [tonConnectUI] = useTonConnectUI();
	const { t } = useTranslation();

	const handleChangeWallet = async () => {
		try {
			await tonConnectUI.disconnect();
			localStorage.removeItem("hasWallet");
			handleConnectWallet();
		} catch (error) {
			console.error("Error while deleting wallet:", error);
		}
	};

	return (
		<>
			<WalletOptionsContainer>
				<WalletInfoOption>
					<span>{t("wallet.address")}</span>
					<WalletAddress>{shortenAddress(wallet)}</WalletAddress>
					<WalletNote>{t("wallet.mustBeOneWallet")}</WalletNote>
				</WalletInfoOption>
				<WalletOption onClick={handleChangeWallet}>
					<OptionTitle>
						<Icon src={RefreshIcon} />
						<span>{t("wallet.changeWallet")}</span>
					</OptionTitle>
					<Icon
						src={ArrowRightIcon}
						alt=""
					/>
				</WalletOption>
			</WalletOptionsContainer>
		</>
	);
};

export default WalletManage;
