import styled from "styled-components";
import SearchNotFoundImg from "../../assets/searchNotFound.webp";
import { colors } from "../../styles/Variables.style";

export const MainContainer = styled.div`
	position: relative;
	padding: 16px 0;
	color: white;
	display: flex;
	flex-direction: column;
	flex: 1;
	z-index: 1;

	${({ noResults }) =>
		noResults &&
		`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${SearchNotFoundImg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: -1;
    }
  `};
`;

export const SearchInputWrap = styled.div`
	position: relative;
`;

export const SearchContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
`;

export const SearchTitle = styled.h2`
	font-weight: 800;
	width: 100%;
	font-size: 18px;
	line-height: 26px;
	color: white;
	padding: 16px 16px 0;
`;

export const RandomButton = styled.button`
	width: 100%;
	padding: 11px 16px;
	background-color: white;
	color: black;
	border-radius: 12px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: center;

	box-shadow: 0 0.4vh 0 0 rgba(59, 100, 248, 1);
	cursor: pointer;
	transition: transform 0.3s ease;

	&:hover {
		transform: scale(1.01);
	}
`;

export const RandomIcon = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 8px;
`;

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: relative;
	top: 2vh;
`;

export const NoGamesFoundContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const NoGamesText = styled.h2`
	font-size: 28px;
	font-weight: bold;
	color: white;
	font-weight: 800;
	line-height: 36px;
`;

export const CheckText = styled.p`
	font-size: 12px;
	color: ${colors.lightGray};
	margin-top: 8px;
	font-weight: 400;
`;
