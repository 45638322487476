import styled from "styled-components";
import { colors } from "../../../styles/Variables.style";

export const WalletOptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 16px;
`;

export const WalletOption = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 16px 0;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
`;

export const WalletInfoOption = styled(WalletOption)`
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	cursor: default;
	border-bottom: 1px solid ${colors.darkGrayActive};
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
`;

export const WalletAddress = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
`;

export const OptionTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
	font-weight: 500;
`;

export const WalletNote = styled.p`
	color: ${colors.lightGray};
`;

export const Icon = styled.img`
	width: 20px;
	height: 20px;
`;
