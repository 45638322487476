import React from "react";
import CurrencyIcon from "../CurrencyIcon/CurrencyIcon";
import { CourseText, CurrencyItem, CurrencyWrap } from "./CurrencyManage.style";
import { Currency } from "../../../constants/Currency";
import { useWalletInfo } from "../../../contexts/WalletInfoContext";

const CurrencyManage = ({ setCurrency }) => {
	const { courses } = useWalletInfo();
	return (
		<>
			<CurrencyWrap>
				{Object.entries(Currency).map((item) => (
					<CurrencyItem onClick={() => setCurrency(item[0])}>
						<CurrencyIcon
							key={item[0]}
							currencyVal={item[1]}
							withName
						/>
						{courses[item[1]] && (
							<CourseText>
								{courses[item[1]]} {item[0]} = 1 USDT
							</CourseText>
						)}
					</CurrencyItem>
				))}
			</CurrencyWrap>
		</>
	);
};

export default CurrencyManage;
