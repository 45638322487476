import { isTMA, retrieveLaunchParams } from "@telegram-apps/sdk";

const devInitData = process.env.REACT_APP_DEV_TELEGRAM_INIT_DATA;

const getInitData = () => {
	let initData = {};
	let initDataRaw = "";
	let platform = "unknown";

	if (isTMA("simple")) {
		const launchParams = retrieveLaunchParams();
		initData = launchParams.initData;
		initDataRaw = launchParams.initDataRaw;
		platform = launchParams.platform;
	}

	const result = process.env.REACT_APP_ENV === "DEV" && devInitData ? devInitData : initDataRaw;

	return {
		initDataRaw: result,
		initData,
		platform: platform,
	};
};

export default getInitData;
