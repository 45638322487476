import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	GameContainer,
	GameImage,
	GameTitle,
	GameProvider,
	ButtonContainer,
	DemoButton,
	PlayButton,
	ErrorMessage,
	LikeButton,
	LikeIcon,
	GameTitleContainer,
} from "./Game.style";
import {
	addToFavorites,
	removeFromFavorites,
	getFavoriteGames,
	startBgamingGame,
	startZephyrGame,
} from "../../services/userService";
import Loader from "../../components/Loader/Loader";
import trackMixpanel from "../../utils/trackEvent";
import Providers from "../../constants/Providers";
import { useGame } from "../../contexts/GameContext";
import { useTranslation } from "../../contexts/TranslationContext";
import useTelegram from "../../hooks/useTelegram";
import { IframeContainer } from "../../styles/Shared.style";

const Game = () => {
	const [gameUrl, setGameUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const { game, setGame } = useGame();
	const { language } = useTelegram();
	const { t } = useTranslation();

	const handleStartGame = async (isDemo) => {
		let gameId = game?.game_id;
		setIsLoading(true);
		try {
			let gameUrl;

			switch (game.provider) {
				case Providers.BGAMING:
					gameUrl = await startBgamingGame(gameId, language, isDemo);
					break;
				case Providers.ZEPHYR:
					gameUrl = await startZephyrGame(gameId, isDemo);
					break;
				default:
					return;
			}

			setGameUrl(gameUrl);
			trackMixpanel(isDemo ? "Demo launch" : "Game launch", { game_name: game?.name });
		} catch (error) {
			setError(t("game.errorStart"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleLike = async () => {
		let gameId = game?.game_id;
		if (game?.is_favourite) {
			await removeFromFavorites(gameId);
			setGame({ ...game, is_favourite: false });
		} else {
			await addToFavorites(gameId, game?.provider, game?.name, game?.src);
			setGame({ ...game, is_favourite: true });
		}
	};

	return (
		<GameContainer>
			{isLoading ? (
				<Loader />
			) : gameUrl ? (
				<IframeContainer bgColor="black">
					<iframe
						src={gameUrl}
						title="Game"
						allowFullScreen
					/>
				</IframeContainer>
			) : (
				<>
					<GameTitleContainer>
						<div>
							<GameTitle>{game?.name}</GameTitle>
							<GameProvider>{game?.provider}</GameProvider>
						</div>
						<LikeButton onClick={handleLike}>
							<LikeIcon isLiked={game?.is_favourite} />
						</LikeButton>
					</GameTitleContainer>

					<GameImage
						src={`${game?.src ? process.env.REACT_APP_BACKEND_URL + game.src : null}`}
						alt={game?.name}
					/>

					{error && <ErrorMessage>{error}</ErrorMessage>}

					<ButtonContainer>
						<DemoButton onClick={() => handleStartGame(true)}>{t("demoMode")}</DemoButton>
						<PlayButton onClick={() => handleStartGame(false)}>{t("play")}</PlayButton>
					</ButtonContainer>
				</>
			)}
		</GameContainer>
	);
};

export default Game;
