import React, { useEffect } from "react";
import { useTranslation } from "../../contexts/TranslationContext";
import GamesGrid from "../../components/GamesGrid/GamesGrid";
import { useGames } from "../../services/userService";

const GamesSection = ({ gameType, providers, category, horizontal }) => {
	const { t } = useTranslation();
	const { data, isFetching, isError, fetchNextPage, hasNextPage, refetch } = useGames(gameType, providers, category);

	const games = data?.pages.flatMap((page) => page.data) || [];

	useEffect(() => {
		refetch();
	}, [gameType, providers, category, refetch]);

	return (
		<GamesGrid
			isLoading={isFetching}
			isError={isError}
			games={games}
			hasNextPage={hasNextPage}
			loadMore={fetchNextPage}
			horizontal={horizontal}
			onRetry={refetch}
			errorMessage={t(`games.${gameType}GamesWillBeHere`)}
		/>
	);
};

export default GamesSection;
