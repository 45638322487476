import styled from "styled-components";
import { InputStyles } from "../../styles/Shared.style";

export const InputWrap = styled.div`
	position: relative;
	width: 100%;
`;

export const InputEl = styled.input`
	${InputStyles}

	&::placeholder {
		color: #777c85;
		font-size: 14px;
	}

	&:focus {
		outline: none;
		border-color: #0088cc;
	}

	&[type="number"]::-webkit-outer-spin-button,
	&[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}
`;

export const ClearIcon = styled.img`
	cursor: pointer;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translate(0, -50%);
`;
