import React, { useEffect, useState } from "react";
import {
	FooterContainer,
	FooterLogo,
	SupportButtons,
	SupportButton,
	SupportIcon,
	FooterText,
	ArrowIcon,
	DocumentLinks,
	DocumentLink,
	ButtonTitle,
	WarnImage,
} from "./Footer.styles";
import LogoIcon from "../../assets/logo.png";
import ChatIcon from "../../assets/chat.svg";
import MailIcon from "../../assets/mail.svg";
import UrlIcon from "../../assets/urlImg.svg";
import { useTranslation } from "../../contexts/TranslationContext";
import { useModalState } from "../../hooks/useModalState";
import Drawer from "../Drawer/Drawer";
import { IframeContainer } from "../../styles/Shared.style";
import WarnIcon from "../../assets/18plus.webp";

const Footer = () => {
	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();

	const { render, active, openModal, closeModal } = useModalState();
	const [curLink, setCurLink] = useState("");

	const handleClickLink = (link) => {
		setCurLink(link);
		openModal();
	};

	const links = [
		{
			name: t("footer.termsAndConditions"),
			link: "https://docs.google.com/document/d/11ekQ9BercG2tbAtzdnvMab4R7chnDfV2Ce_bZ_39X0A/edit?usp=sharing",
		},
		{
			name: t("footer.privacyPolicy"),
			link: "https://docs.google.com/document/d/1DROfp160i8qQ2PBHTjduJro4-BdG8viPHsP1bcTcofE/edit?usp=sharing",
		},
		{
			name: t("footer.underagePolicy"),
			link: "https://docs.google.com/document/d/1r_D_RIoomVmQIhqTKluooGbHmfUuJrHlWnB-rMXCtws/edit?usp=sharing",
		},
		{
			name: t("footer.responsibleGambling"),
			link: "https://docs.google.com/document/d/1ynAH64o1NXVHDndwzKxMwe58lA5XmCqrmysABKEQwjA/edit?usp=sharing",
		},
	];

	return (
		<FooterContainer>
			<FooterLogo
				src={LogoIcon}
				alt="Beton Logo"
			/>
			<SupportButtons>
				<SupportButton>
					<SupportIcon
						src={ChatIcon}
						alt="Online Chat"
					/>
					<div>
						<ButtonTitle>{t("footer.onlineChat")}</ButtonTitle>
						<span>{t("footer.inTouch24/7")}</span>
					</div>
				</SupportButton>
				<SupportButton>
					<SupportIcon
						src={MailIcon}
						alt="Support"
					/>
					<div>
						<ButtonTitle>{t("footer.support")}</ButtonTitle>
						<span>support@betton</span>
					</div>
				</SupportButton>
			</SupportButtons>

			<DocumentLinks>
				{links.map((item, index) => {
					return (
						<DocumentLink
							key={index}
							onClick={() => handleClickLink(item.link)}
						>
							{item.name}
							<ArrowIcon
								src={UrlIcon}
								alt="Arrow"
							/>
						</DocumentLink>
					);
				})}
			</DocumentLinks>

			<WarnImage src={WarnIcon} />

			<FooterText>{t("footer.copyright", currentYear)}</FooterText>

			{render && (
				<Drawer
					isOpen={active}
					onClose={closeModal}
				>
					<IframeContainer>
						<iframe
							src={curLink}
							title="Docs"
							allowFullScreen
						/>
					</IframeContainer>
				</Drawer>
			)}
		</FooterContainer>
	);
};

export default Footer;
