import React, { useState, useEffect, act } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GamesContainer, GamesWrap } from "./Games.styles";
import TabsNavigation from "../../components/TabsNavigation/TabsNavigation";
import Footer from "../../components/Footer/Footer";
import ProvidersSection from "../../components/ProvidersSection/ProvidersSection";
import { useProviders } from "../../contexts/ProvidersContext";
import GameTypes from "../../constants/GameTypes";
import useTelegram from "../../hooks/useTelegram";
import { SectionTitle } from "../../styles/Shared.style";
import { useNavTabs } from "../../contexts/TabsNavigationContext";
import GamesSection from "../../components/GamesSection/GamesSection";
import { Language } from "../../utils/i18n/getTranslation";

const Games = () => {
	const { language } = useTelegram();
	const { selectedProviders } = useProviders();
	const location = useLocation();
	const { type } = useParams();
	const { navItems } = useNavTabs();
	const [activeNav, setActiveNav] = useState(null);

	useEffect(() => {
		setActiveNav(navItems.find((item) => item.to === `${location.pathname + location.search}`) || null);
	}, [type, navItems, selectedProviders]);

	return (
		<GamesContainer>
			<TabsNavigation />

			{type !== GameTypes.NAVIGATION && <ProvidersSection />}

			<SectionTitle>{activeNav?.label[language] || activeNav?.label[Language.en]}</SectionTitle>

			<GamesSection
				gameType={activeNav ? type : null}
				providers={activeNav?.provider || selectedProviders}
				category={activeNav?.category}
			/>

			<Footer />
		</GamesContainer>
	);
};

export default Games;
