import styled from "styled-components";
import { colors, sizes } from "../../styles/Variables.style";

export const DrawerEl = styled.div`
	position: fixed;
	background: ${colors.bg};
	transition: transform 0.3s ease-in-out;
	overflow: hidden;
	will-change: transform;
	z-index: ${({ zIndex }) => zIndex || 101};

	${({ direction = "bottom" }) => {
		switch (direction) {
			case "top":
				return `
					top: 0; left: 0; right: 0; height: 100%;
					transform: translate3d(0, -100%, 0);
				`;
			case "left":
				return `
					top: 0; left: 0; bottom: 0; width: 100%;
					transform: translate3d(-100%, 0, 0);
				`;
			case "right":
				return `
					top: 0; right: 0; bottom: 0; width: 100%;
					transform: translate3d(100%, 0, 0);
				`;
			default:
				return `
					top: 0; left: 0; right: 0; bottom: 0;
					transform: translate3d(0, 100%, 0);
				`;
		}
	}}

	${({ isOpen }) =>
		isOpen &&
		`
		transform: translate3d(0, 0, 0);
	`}
`;

export const DrawerContent = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

export const DrawerTitleWrap = styled.div`
	display: flex;
	justify-content: space-between;

	padding: ${({ isTitle }) => (isTitle ? "32px" : "16px")} 16px 0;
`;

export const DrawerTitle = styled.div`
	font-weight: 800;
	font-size: 22px;
	line-height: 30px;
`;
