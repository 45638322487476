import React, { useState, useEffect, useReducer, useCallback } from "react";
import { useTonConnectUI, useTonAddress, useTonConnectModal } from "@tonconnect/ui-react";
import {
	WalletContainer,
	InputContainer,
	ButtonTonText,
	TabsContainer,
	TabButton,
	AmountOptions,
	WalletConnectText,
	InfoLabel,
	TopLabel,
	ButtonContainer,
	TransactionHistoryButton,
	ContentWrap,
	Icon,
	WalletOptionRow,
	WalletOptionWrap,
} from "./Wallet.styles";
import { verifyTransaction } from "../../services/userService";
import { withdrawFunds, sendTransaction } from "../../services/tonServices";
import { useLocation } from "react-router-dom";
import trackMixpanel from "../../utils/trackEvent";
import Input from "../../components/Input/Input";
import DrawerMini from "../../components/Drawer/DrawerMini/DrawerMini";
import WalletManage from "./WalletManage/WalletManage";
import StatusModal from "./StatusModal/StatusModal";
import ArrowBottom from "../../assets/arrow-bottom.svg";
import PlusCircle from "../../assets/Wallet/plus-circle.svg";
import { useModalState } from "../../hooks/useModalState";
import { useWalletInfo } from "../../contexts/WalletInfoContext";
import TransactionHistory from "../TransactionHistory/TransactionHistory";
import Drawer from "../Drawer/Drawer";
import { useTranslation } from "../../contexts/TranslationContext";
import CurrencyIcon from "./CurrencyIcon/CurrencyIcon";
import CurrencyManage from "./CurrencyManage/CurrencyManage";
import { Currency } from "../../constants/Currency";
import { shortenAddress } from "../../utils/helpers/shortenAddress";

const handleAmountInput = (value, setValue) => {
	const regex = /^\d*\.?\d{0,2}$/;
	if (regex.test(value)) {
		setValue(value);
	}
};

const ActiveTab = {
	deposit: "deposit",
	withdraw: "withdraw",
};

const ModalType = {
	success: "success",
	error: "error",
	wagering: "wagering",
	loading: "loading",
};

const Wallet = ({ onClose }) => {
	const [amount, setAmount] = useState("");
	const [withdrawAmount, setWithdrawAmount] = useState("");
	const { balance, courses, fetchBalance, calcMinDeposit, minWithdraw, remainingWagering } = useWalletInfo();
	const [tonConnectUI] = useTonConnectUI();
	const wallet = useTonAddress();
	const { open, state: connectModalState } = useTonConnectModal();
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(ActiveTab.deposit);
	const [modalType, setModalType] = useState(null);
	const [isShowModal, setIsShowModal] = useState(false);
	const [currencyKey, setCurrencyKey] = useState(() => {
		const storedCurrencyKey = localStorage.getItem("currencyKey");
		return storedCurrencyKey in Currency ? storedCurrencyKey : "USDT";
	});
	const { t } = useTranslation();
	const {
		render: renderWalletManage,
		active: walletManageActive,
		openModal: openWalletManage,
		closeModal: closeWalletManage,
	} = useModalState();
	const {
		render: renderCurrencyManage,
		active: currencyManageActive,
		openModal: openCurrencyManage,
		closeModal: closeCurrencyManage,
	} = useModalState();
	const {
		render: renderTransactions,
		active: transactionsActive,
		openModal: openTransactions,
		closeModal: closeTransactions,
	} = useModalState();

	useEffect(() => {
		if (localStorage.getItem("hasWallet")) return;
		if (connectModalState.status === "closed" && connectModalState.closeReason === "wallet-selected") {
			trackMixpanel("Wallet connected", { balance });
			localStorage.setItem("hasWallet", true);
		}
	}, [connectModalState, balance]);

	useEffect(() => {
		trackMixpanel("Cashbox", { referrer: location.pathname });
	}, [location.pathname]);

	const handleConnectWallet = useCallback(() => {
		trackMixpanel("Wallet connect", { balance });
		tonConnectUI.setConnectRequestParameters({ state: "ready", items: [{ name: "ton_addr" }] });
		open();
	}, [tonConnectUI, balance, open]);

	const handleSendTransaction = useCallback(async () => {
		if (!wallet) return await handleConnectWallet();
		if (amount < calcMinDeposit(currencyKey)) return;
		try {
			const transaction = await sendTransaction(wallet, amount, "Пополнение баланса", currencyKey);
			if (!transaction?.messages) throw new Error("Некорректная структура транзакции");

			const result = await tonConnectUI.sendTransaction({
				validUntil: Math.floor(Date.now() / 1000) + 60,
				messages: transaction.messages.map(({ to, value, payload }) => ({
					address: to,
					amount: value,
					payload,
					bounce: true,
				})),
				keepOpened: true,
			});

			setModalType(ModalType.loading);
			setIsShowModal(true);
			await verifyTransaction(result.boc, wallet, amount, "Deposit", currencyKey);
			await fetchBalance();
			setModalType(ModalType.success);
			trackMixpanel("Deposit successful", { amount });
		} catch (error) {
			setModalType(ModalType.error);
			setIsShowModal(true);
			trackMixpanel("Deposit error", { error: error.message });
		}
	}, [wallet, amount, calcMinDeposit, tonConnectUI, fetchBalance, handleConnectWallet]);

	const handleWithdraw = useCallback(async () => {
		if (!wallet) return handleConnectWallet();
		if (withdrawAmount < minWithdraw || parseFloat(withdrawAmount) <= 0) return;
		try {
			if (remainingWagering > 0) return setModalType(ModalType.wagering), setIsShowModal(true);
			await withdrawFunds(wallet, withdrawAmount, "Withdrawal from Casino");
			await fetchBalance();
			setModalType(ModalType.success);
			setIsShowModal(true);
		} catch {
			setModalType(ModalType.error);
			setIsShowModal(true);
		}
	}, [wallet, withdrawAmount, minWithdraw, remainingWagering, handleConnectWallet]);

	const handleTabClick = useCallback(
		(tab) => {
			if (tab === ActiveTab.withdraw && remainingWagering > 0)
				return setModalType(ModalType.wagering), setIsShowModal(true);
			setActiveTab(tab);
		},
		[remainingWagering],
	);

	const handleTransactionHistory = useCallback(() => {
		openTransactions();
	}, []);

	const handleSetCurrencyKey = useCallback((currencyKey) => {
		setCurrencyKey(currencyKey);
		localStorage.setItem("currencyKey", currencyKey);
		closeCurrencyManage();
	}, []);

	return (
		<>
			<WalletContainer>
				<TabsContainer>
					{Object.entries(ActiveTab).map((item) => {
						return (
							<TabButton
								key={item[1]}
								active={activeTab === item[1]}
								onClick={() => handleTabClick(item[1])}
							>
								{t(`wallet.${item[1]}`)}
							</TabButton>
						);
					})}
				</TabsContainer>

				<ContentWrap>
					<WalletOptionWrap>
						<TopLabel>{t("wallet")}</TopLabel>

						<WalletOptionRow onClick={!wallet ? handleConnectWallet : openWalletManage}>
							{!wallet && (
								<Icon
									src={PlusCircle}
									alt="+"
								/>
							)}

							<WalletConnectText>{wallet ? shortenAddress(wallet) : t("wallet.connectWallet")}</WalletConnectText>
							<Icon
								src={ArrowBottom}
								alt=">"
							/>
						</WalletOptionRow>
					</WalletOptionWrap>

					{activeTab === ActiveTab.deposit && (
						<WalletOptionWrap>
							<TopLabel>{t("wallet.currency")}</TopLabel>

							<WalletOptionRow onClick={openCurrencyManage}>
								<CurrencyIcon
									currencyVal={Currency[currencyKey]}
									withName
								/>
								<Icon
									src={ArrowBottom}
									alt=">"
								/>
							</WalletOptionRow>
							{courses[Currency[currencyKey]] && (
								<InfoLabel>
									{courses[Currency[currencyKey]]} {currencyKey} = 1 USDT
								</InfoLabel>
							)}
						</WalletOptionWrap>
					)}

					{activeTab === ActiveTab.deposit && (
						<>
							<InputContainer>
								<TopLabel>{t("wallet.depositAmount")}</TopLabel>
								<Input
									type="number"
									value={amount}
									onChange={(e) => handleAmountInput(e.target.value, setAmount)}
									placeholder={"0.00"}
								/>
								<InfoLabel>
									{t("wallet.minAmount", calcMinDeposit(currencyKey))} {currencyKey}
								</InfoLabel>
								<AmountOptions>
									{[50, 100, 150, 200, 1500].map((option) => (
										<button
											key={option}
											onClick={() => setAmount(option)}
										>
											{option}
										</button>
									))}
								</AmountOptions>
							</InputContainer>
						</>
					)}

					{activeTab === ActiveTab.withdraw && (
						<>
							<InputContainer>
								<TopLabel>{t("wallet.withdrawAmount")}</TopLabel>
								<Input
									type="number"
									value={withdrawAmount}
									onChange={(e) => handleAmountInput(e.target.value, setWithdrawAmount)}
									placeholder={"0.00"}
								/>
								<InfoLabel>{t("wallet.minAmount", minWithdraw)} USDT</InfoLabel>
							</InputContainer>
						</>
					)}
				</ContentWrap>

				<ButtonContainer>
					<TransactionHistoryButton onClick={handleTransactionHistory}>
						{t("transactionHistory")}
					</TransactionHistoryButton>
					{activeTab === ActiveTab.deposit ? (
						<ButtonTonText onClick={handleSendTransaction}>{t("wallet.doDeposit")}</ButtonTonText>
					) : (
						<ButtonTonText onClick={handleWithdraw}>{t("wallet.doWithdraw")}</ButtonTonText>
					)}
				</ButtonContainer>
			</WalletContainer>

			{renderWalletManage && (
				<DrawerMini
					isOpen={walletManageActive && wallet}
					onClose={closeWalletManage}
					title={t("wallet")}
				>
					<WalletManage handleConnectWallet={handleConnectWallet} />
				</DrawerMini>
			)}

			{renderTransactions && (
				<Drawer
					isOpen={transactionsActive}
					onClose={closeTransactions}
					title={t("transactionHistory")}
					zIndex={102}
					isBackButton={false}
					direction={"right"}
				>
					<TransactionHistory />
				</Drawer>
			)}

			{renderCurrencyManage && (
				<DrawerMini
					isOpen={currencyManageActive}
					onClose={closeCurrencyManage}
					title={t("wallet.currency")}
				>
					<CurrencyManage setCurrency={handleSetCurrencyKey} />
				</DrawerMini>
			)}

			<StatusModal
				isShow={isShowModal}
				onClose={() => setIsShowModal(null)}
				modalType={modalType}
				activeTab={activeTab}
				remainingWagering={remainingWagering}
			/>
		</>
	);
};

export default Wallet;
