import styled from "styled-components";
import { colors } from "../../../styles/Variables.style";

export const CurrencyWrap = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
`;

export const CurrencyItem = styled.div`
	padding: 16px 0;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:not(:last-child) {
		border-bottom: 1px solid ${colors.darkGray};
	}
`;

export const CourseText = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: ${colors.lightGray};
	font-weight: 400;
`;
