import React from "react";
import { Dropdown, DropdownItem, DropdownItemLoading } from "./SearchDropdown.style";

const SearchDropdown = ({ isDropdownOpen, searchedGames, isSearchedLoading, handleSelectGame }) => {
	return (
		<>
			<Dropdown isDropdownOpen={isDropdownOpen}>
				{isSearchedLoading &&
					Array.from({ length: 4 }).map((_, index) => (
						<DropdownItem key={index}>
							<DropdownItemLoading />
						</DropdownItem>
					))}

				{!isSearchedLoading &&
					searchedGames?.length > 0 &&
					searchedGames.map((game) => (
						<DropdownItem
							key={game.id}
							onMouseDown={() => handleSelectGame(game)}
						>
							{game.name}
						</DropdownItem>
					))}
			</Dropdown>
		</>
	);
};

export default SearchDropdown;
