import React, { useState, useRef, useCallback } from "react";
import {
	HistoryContainer,
	TransactionListContainer,
	TransactionItem,
	TransactionDetails,
	FilterButton,
	TabsContainer,
	TransactionIcon,
	TransactionDate,
	CloseIcon,
	TabsWrapper,
	TransactionLoading,
	ErrorWrap,
} from "./TransactionHistory.styles";
import CloseDark from "../../assets/close-dark.svg";
import ReceiveIcon from "../../assets/Wallet/receive.svg";
import SendIcon from "../../assets/Wallet/send.svg";
import { transactionsOnPageLimit } from "../../constants/Settings";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { RetryButton } from "../../styles/Shared.style";
import { useTranslation } from "../../contexts/TranslationContext";
import { useTransactions } from "../../services/userService";

const TransactionHistory = () => {
	const [filter, setFilter] = useState("all");
	const transactionListRef = useRef(null);
	const loadMoreRef = useRef(null);
	const { t } = useTranslation();

	const tabs = [
		{
			value: "Deposit",
			text: t("onlyDeposits"),
		},
		{
			value: "withdrawal",
			text: t("onlyWithdrawals"),
		},
	];

	const { data, isFetching, isError, fetchNextPage, hasNextPage, refetch } = useTransactions(filter);

	const transactions = data?.pages.flatMap((page) => page.data) || [];

	const handleFilterChange = (newFilter) => {
		setFilter(newFilter);
	};

	const observerCallback = useCallback(
		(entries) => {
			const target = entries[0];
			if (target.isIntersecting && hasNextPage && !isFetching) {
				fetchNextPage();
			}
		},
		[hasNextPage, isFetching, fetchNextPage],
	);

	useIntersectionObserver(observerCallback, transactionListRef, loadMoreRef, {
		root: transactionListRef.current,
		rootMargin: "0px 0px 200px 0px",
	});

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return `${date.toLocaleDateString()} • ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
	};

	return (
		<>
			<TabsWrapper>
				<TabsContainer>
					{tabs.map((item) => (
						<FilterButton
							key={item.value}
							active={filter === item.value}
							onClick={() => handleFilterChange(item.value)}
						>
							{item.text}
							{filter === item.value && (
								<CloseIcon
									src={CloseDark}
									alt="Close"
									onClick={(event) => {
										event.stopPropagation();
										handleFilterChange("all");
									}}
								/>
							)}
						</FilterButton>
					))}
				</TabsContainer>
			</TabsWrapper>

			<TransactionListContainer ref={transactionListRef}>
				{transactions.length > 0 &&
					transactions.map((transaction) => (
						<TransactionItem key={transaction.id}>
							<TransactionIcon
								src={transaction.transaction_type === "Deposit" ? ReceiveIcon : SendIcon}
								alt={transaction.transaction_type === "Deposit" ? "Deposit" : "Withdrawal"}
							/>
							<TransactionDetails>
								<div>
									{transaction.transaction_type === "Deposit" ? "+" : "-"} {transaction.amount}{" "}
									{transaction.jetton_name}
								</div>
								<TransactionDate>{formatDate(transaction.created_at)}</TransactionDate>
							</TransactionDetails>
						</TransactionItem>
					))}

				{isFetching &&
					Array.from({ length: transactionsOnPageLimit }).map((_, index) => (
						<TransactionItem key={index}>
							<TransactionLoading />
						</TransactionItem>
					))}

				{isError && (
					<ErrorWrap>
						<RetryButton onClick={() => refetch()}>{t("retry")}</RetryButton>
					</ErrorWrap>
				)}

				<div
					ref={loadMoreRef}
					style={{ width: "100%", height: "1px" }}
				></div>
			</TransactionListContainer>
		</>
	);
};

export default TransactionHistory;
