import { useEffect, useRef, useCallback } from "react";

const useIntersectionObserver = (callback, wrapRef, elementRef, options = {}) => {
	useEffect(() => {
		if (wrapRef !== null && (!elementRef.current || !wrapRef.current)) return;

		const observer = new IntersectionObserver(callback, options);
		observer.observe(elementRef.current);

		return () => {
			if (elementRef.current) {
				observer.unobserve(elementRef.current);
			}
		};
	}, [callback, options]);

	return elementRef;
};

export default useIntersectionObserver;
